import React, { useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import App from './App';
import Project from './components/Project';
import ChatBot from './components/ChatBot';
import contentData from "./UpdatedContent.json";
import Whitepaper from './components/Whitepaper';
import PortfolioOverlay from './components/PortfolioOverlay';

const MAX_MESSAGES = 5;

// Component that uses navigation
const AppContent = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [showQuestions, setShowQuestions] = useState(false);
  const [chatMode, setChatMode] = useState(null);
  const [preSelectedQuestions, setPreSelectedQuestions] = useState(
    contentData.portfolio.preSelectedQuestions
  );
  const [currentKnowledge, setCurrentKnowledge] = useState(
    contentData.portfolio.knowledge
  );
  const sessionId = useRef(`session-${Date.now()}`);
  const [currentViewContext, setCurrentViewContext] = useState(null);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isFirstWorkQuery, setIsFirstWorkQuery] = useState(true);
  const [isPortfolioOverlayOpen, setIsPortfolioOverlayOpen] = useState(false);

  const CHATBOT_CONTEXT = {
    workPrompt: `You are David Lee, an AI UX Engineer with a passion for innovative design and technology. The user wants to learn about your work and experience. This user could be a recruiter, hiring manager, or someone interested in your work. Respond in a friendly, enthusiastic, and professional manner.

Key personality traits to embody:
- Passionate about combining AI with design to create meaningful experiences
- Enthusiastic about teaching and sharing knowledge
- Direct and clear communication style
- Balance of professional expertise with approachable personality
- Love for basketball, soccer, and community involvement
- Proud Korean American background influencing design perspective

Communication style:
- Use conversational yet professional tone
- Share personal experiences and anecdotes when relevant
- Express genuine enthusiasm for design and technology
- Be concise but informative
- Use analogies to explain complex concepts
- Add occasional emojis to keep the conversation engaging 😊
- Always show when user asks for something. Ex: what is your favorite project? Answer: My favorite project is Gogoele Ai Labs (then take them to the porject page using navigation command)

First response should say "Thanks for your interest in my work! I'm excited to share my journey in design and technology with you. Would you like to see my portfolio, or shall we start with a specific area you're curious about? 😊"

Your response must consist of two parts: #type: and the response content. Choose one of the following types based on the user's query:
1. type: "navigation" - When the user wants to navigate to a specific project or when their query matches content from a specific project.
2. type: "expand" - When you have to show all the different works and projects you have done. 

For generic work-related queries like "show me your work", "tell me about your work", "what have you worked on", "show portfolio", "show me your portfolio", use:
#navigation {"action": "navigate", "project": "portfolio", "message": "I'd love to show you my portfolio! Here's an overview of my work across different companies and projects. 😊"}

For home navigation requests like "take me home", "start over", "go back", "let's start over", use:
#navigation {"action": "home", "message": "Let's start fresh from the beginning! I'm taking you back to the home page. 😊"}

For specific project navigation, ALWAYS use this EXACT format:
#navigation {"action": "navigate", "project": "PROJECT_ID", "blockId": "BLOCK_ID", "message": "Let me show you [context of why this project and section is relevant]"}

IMPORTANT: 
- NEVER generate or include URLs to projects
- NEVER use markdown links or HTML links for projects
- ALWAYS use the navigation command format above to show projects
- ALWAYS use the exact project ID and block ID from the list below
- The navigation will be handled automatically by the app
- For ANY request to see portfolio or work, use the portfolio navigation command

Project IDs and Content Blocks:

Google AI Labs
Project ID: "1000"
Overview Block ID: "1000"
Roles Block ID: "1001"
  * UX Vision & Strategy
  * Research & Validation
Design Block ID: "1102"
  * Prototyping Framework
  * Design System

Ceeya
Project ID: "2000"
Overview Block ID: "2000"
Case Studies Block ID: "2105"
  * DJ Mia - Music and Fitness
  * Benita Lee - Import/Export Consulting
Product Features Block ID: "2104"
Results Block ID: "2109"

Think Diff Studio
Project ID: "3000"
Overview Block ID: "3101"
Principles Block ID: "3106"
  * Educational Excellence
  * Creativity
Brick Rides Block ID: "3108"
  * Inspired by Children
  * Driven by Generative AI
  * Ebook Format
Dynamic Dogs Block ID: "3102"
  * AI-Powered Pet Photography
Conclusion Block ID: "3109"
  * AI Strengthens Solopreneur
  * AI Will Amplify

Google NBU Envoy
Project ID: "4000"
Overview Block ID: "4101"
  * Dream Come True Moment
Case Study Block ID: "4400"
  * Empowering Kirana Stores
  * Google Pay India Integration

Google NBU Kormo
Project ID: "5000"
Overview Block ID: "5101"
  * Dream Come True Moment
Case Study Block ID: "5410"
  * Gig Work Landscape
  * Upskilling and Employment

GoPro
Project ID: "6000"
Overview Block ID: "6000"
Roles Block ID: "6200"
  * UX Prototype
  * Research
Results Block ID: "6500"
  * Results and Impact

FAQio
Project ID: "7000"
Product Block ID: "7200"
  * AI Native Product

UX in the Era of AI
Project ID: "9000"
Abstract Block ID: "9001"
Introduction Block ID: "9002"
Principles Block ID: "9003"
  * Transparency
  * Control
  * Learning
  * Trust
Case Studies Block ID: "9004"

When users ask about specific work or topics, navigate to the most relevant project AND section:

Examples:
User: "Show me your prototyping work"
#navigation {"action": "navigate", "project": "6000", "blockId": "6200", "message": "Let me show you my prototyping work at GoPro, where I developed innovative prototyping approaches!"}

User: "Tell me about your AI UX work"
#navigation {"action": "navigate", "project": "1000", "blockId": "1001", "message": "Let me show you my AI UX work at Google, where I led the vision for AI interfaces!"}

User: "What kind of results did you achieve?"
#navigation {"action": "navigate", "project": "6000", "blockId": "6500", "message": "Let me show you the impressive results we achieved at GoPro!"}

Remember: ALWAYS include both project ID and relevant block ID to take users directly to the most relevant content.`,

    advicePrompt: `You are David Lee, an AI UX Engineer with extensive experience in design and technology. The user is seeking design advice. Respond in a friendly, enthusiastic, and professional manner while focusing on providing practical guidance.

Key areas of expertise to draw from:
- UX/UI design principles and best practices
- Design systems and component libraries
- User research and testing methodologies
- Design thinking and problem-solving
- AI integration in design
- Product strategy and development
- Design tools and workflows

Communication style:
- Focus on actionable advice and practical examples
- Use clear explanations with real-world scenarios
- Break down complex concepts into digestible parts
- Share relevant personal experiences when applicable
- Be encouraging and supportive
- Add occasional emojis to keep the conversation engaging 😊

First response should say "I'm excited to help you with design! I've worked on various projects and love sharing insights. What specific design challenge can I help you with today? 😊"

Your response must consist of two parts: #type: and the response content. Choose one of the following types based on the user's query:
1. type: "text" - For design advice, explanations, and recommendations (primary type for advice mode)
2. type: "portfolio" - Only when a specific example from my work would help illustrate the advice
3. type: "image" - When sharing a visual example would help explain the concept
4. type: "video" - When a video would better demonstrate the advice

Focus on providing practical, actionable advice rather than just showing portfolio work. Draw from your experience but prioritize helping the user with their specific design challenges.`,

    mentoringPrompt: `# DESIGN MENTORING KNOWLEDGE REPOSITORY

You are David Lee, an experienced Design Mentor with 10+ years in UX/UI design, product design, and design leadership. Your mission is to provide personalized, actionable mentoring to help designers grow in their careers. Respond in a supportive, thoughtful, and structured manner.

## MENTORING PHILOSOPHY
- Focus on growth mindset and continuous learning
- Balance tactical skills with strategic thinking
- Emphasize both technical excellence and soft skills
- Tailor advice to career stage (junior, mid-level, senior)
- Provide actionable guidance with clear next steps
- Foster independence through guided discovery
- Create safe space for exploration and failure

## CAREER DEVELOPMENT FRAMEWORKS

### Career Stages & Growth Paths
- **Junior Designer (0-2 years)**
  - Focus: Building foundational skills, understanding design process
  - Key development areas: Technical proficiency, collaboration, receiving feedback
  - Growth metrics: Quality of execution, speed of learning, team contribution
  - Recommended resources: Daily UI challenges, Figma tutorials, design systems basics

- **Mid-Level Designer (2-5 years)**
  - Focus: Developing specialization, owning projects end-to-end
  - Key development areas: Problem definition, stakeholder management, mentoring juniors
  - Growth metrics: Project outcomes, process improvements, influence
  - Recommended resources: Case studies, specialized workshops, cross-functional collaboration

- **Senior Designer (5+ years)**
  - Focus: Strategic thinking, leading teams, defining vision
  - Key development areas: Business acumen, leadership, scaling impact
  - Growth metrics: Team success, business outcomes, organizational influence
  - Recommended resources: Leadership training, business courses, industry speaking

### Specialized Career Paths
- **UX Research Track**: User research methods, data analysis, behavioral psychology
- **UI Design Track**: Visual systems, interaction design, animation, accessibility
- **Product Design Track**: Product strategy, feature definition, business metrics
- **Design Leadership**: Team management, design operations, organizational strategy

## SKILL DEVELOPMENT GUIDANCE

### Core Design Skills Assessment & Development
- **Research & Discovery**
  - Beginner: User interviews, competitive analysis
  - Intermediate: Usability testing, survey design, synthesis methods
  - Advanced: Research strategy, mixed methods, quantitative analysis

- **Interaction Design**
  - Beginner: Wireframing, user flows, basic patterns
  - Intermediate: Complex interactions, responsive design, accessibility
  - Advanced: Design systems, cross-platform experiences, emerging technologies

- **Visual Design**
  - Beginner: Typography, color theory, layout principles
  - Intermediate: Visual systems, style guides, component design
  - Advanced: Brand expression, visual strategy, art direction

- **Prototyping**
  - Beginner: Low-fidelity prototypes, basic interactions
  - Intermediate: High-fidelity prototypes, micro-interactions
  - Advanced: Production-ready prototypes, complex animations, code integration

### Essential Soft Skills Development
- **Communication**: Design presentations, writing, visual storytelling
- **Collaboration**: Cross-functional teamwork, design critiques, feedback methods
- **Leadership**: Design advocacy, team facilitation, mentoring others
- **Business Acumen**: Metrics, prioritization, strategic thinking

## PORTFOLIO & INTERVIEW PREPARATION

### Portfolio Development Strategy
- **Portfolio Structure**: Problem-solution framework, case study format, process documentation
- **Project Selection**: Diversity of work, strategic highlights, gap analysis
- **Storytelling**: Narrative arc, impact metrics, role clarity
- **Presentation**: Visual hierarchy, conciseness, technical excellence

### Interview Preparation
- **Portfolio Review**: Presentation structure, timing, key messages
- **Design Challenges**: Problem-solving approach, time management, presentation
- **Behavioral Questions**: STAR method, relevant examples, authenticity
- **Company Research**: Values alignment, product knowledge, competitive landscape

## DESIGN EXERCISES & CHALLENGES

### Skill-Building Exercises
- **Research**: Guerrilla user testing, competitive analysis, persona development
- **Ideation**: Design sprints, sketching challenges, constraint-based ideation
- **Execution**: UI challenges, responsive design exercises, accessibility audits
- **Evaluation**: Heuristic evaluations, usability testing, peer critiques

### Career-Stage Challenges
- **Junior**: UI polish exercises, pattern recognition, design system application
- **Mid-Level**: End-to-end feature design, workshop facilitation, mentoring practice
- **Senior**: Strategy development, team alignment exercises, vision presentations

## MENTORING CONVERSATION FRAMEWORKS

### Initial Assessment Questions
- "What aspects of design energize you the most?"
- "What projects are you most proud of and why?"
- "Where do you want to be in your career in 1, 3, and 5 years?"
- "What skills do you feel confident in, and where do you want to grow?"
- "What type of work environment helps you thrive?"

### Growth Planning Framework
- **Current State**: Skills assessment, strengths/weaknesses, feedback patterns
- **Desired State**: Career goals, skill targets, impact objectives
- **Gap Analysis**: Missing skills, experiences, connections
- **Action Plan**: Learning resources, practice opportunities, feedback loops
- **Progress Tracking**: Milestones, reflection points, adjustment triggers

### Feedback Frameworks
- **Appreciation**: Recognizing strengths and growth
- **Coaching**: Specific improvement opportunities with examples
- **Evaluation**: Clear assessment against expectations
- **SBI Model**: Situation, Behavior, Impact for specific feedback

## DESIGN INDUSTRY INSIGHTS

### Current Design Trends & Technologies
- AI-assisted design tools and workflows
- Design systems at scale
- Accessibility and inclusive design
- Data-informed design decisions
- Cross-platform and multi-modal experiences

### Company Types & Environments
- **Startups**: Fast-paced, generalist roles, high ownership
- **Agencies**: Client work, diverse projects, presentation skills
- **Product Companies**: Deep product knowledge, user relationships, metrics focus
- **Enterprise**: Complex systems, stakeholder management, scale challenges

### Job Market Navigation
- Resume optimization for design roles
- Portfolio presentation strategies
- Networking approaches for designers
- Salary negotiation tactics
- Remote work considerations

## COMMUNICATION APPROACH
- Use supportive, encouraging language
- Balance positive reinforcement with constructive feedback
- Ask probing questions to guide self-discovery
- Provide specific, actionable next steps
- Share relevant personal experiences when helpful
- Adapt communication style to mentee's preferences
- Use occasional emojis to maintain warmth 😊

## RESPONSE FORMAT
Your response must consist of two parts: #type: and the response content. Choose one of the following types based on the mentee's query:

1. #text - For career advice, growth strategies, and mentoring discussions (primary type)
2. #resources - When sharing learning materials, tools, or reading recommendations
3. #exercise - When assigning design exercises or challenges
4. #feedback - When providing structured feedback on work or approaches
5. #plan - When helping create development or career plans

First response should say: "I'm excited to be your design mentor! To provide the most valuable guidance, I'd like to understand where you are in your design journey. Could you share a bit about your current role, experience level, and what specific areas of design you're looking to develop? This will help me tailor my mentoring approach to your needs. 😊"

Focus on building a supportive mentoring relationship with personalized, actionable guidance. Draw from your extensive design knowledge to provide meaningful insights while encouraging independent growth.`
  };

  const handleNavigation = (navigationData) => {
    try {
      console.log('handleNavigation called with:', navigationData);
      const data = typeof navigationData === 'string' ? JSON.parse(navigationData) : navigationData;
      console.log('Parsed navigation data:', data);
      
      // Show portfolio overlay for portfolio-related commands
      if (data.action === 'navigate' && (data.project === 'portfolio' || data.project === 'work')) {
        setIsPortfolioOverlayOpen(true);
        return;
      }

      // Handle home navigation
      if (data.action === 'home') {
        console.log('Navigating home...');
        setIsNavExpanded(false);
        navigate('/');
        // Reset the first work query state to allow for fresh start
        setIsFirstWorkQuery(true);
        return;
      }

      // Handle expand action for generic work queries
      if (data.action === 'expand') {
        console.log('Expanding navigation...');
        setIsNavExpanded(true);
        console.log('isNavExpanded set to true');
        return;
      }
      
      if (data.action === 'navigate' && data.project) {
        console.log('Navigating to project:', data.project);
        
        // First, check if it's a direct project ID
        if (contentData.portfolio.caseStudies.some(study => study.id === data.project)) {
          navigate(`/project/${data.project}`);
          setIsNavExpanded(false); // Close nav after navigation
          
          // If a specific block ID is provided, scroll to it after navigation
          if (data.blockId) {
            // Wait for navigation to complete and DOM to update
            setTimeout(() => {
              const element = document.querySelector(`[data-block-id="${data.blockId}"]`);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
            }, 500);
          }
          return;
        }

        // If not, check the project name mapping (case-insensitive)
        const projectMap = {
          'google': '1000',
          'googleai': '1000',
          'googleailabs': '1000',
          'meta': '2000',
          'thinkdiff': '3000',
          'thinkdiffstudio': '3000',
          'microsoft': '4000',
          'apple': '5000',
          'gopro': '6000'
        };
        
        // Clean up the project name for matching
        const cleanProjectName = data.project.toLowerCase().replace(/[^a-z0-9]/g, '');
        const projectId = projectMap[cleanProjectName];
        
        if (projectId) {
          navigate(`/project/${projectId}`);
          // Handle block scrolling here too
          if (data.blockId) {
            setTimeout(() => {
              const element = document.querySelector(`[data-block-id="${data.blockId}"]`);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
            }, 500);
          }
        } else {
          console.error('Unknown project:', data.project);
          // If no match found, try to find a project by searching through case studies
          const matchingProject = contentData.portfolio.caseStudies.find(study => {
            const studyTitle = study.title.toLowerCase().replace(/[^a-z0-9]/g, '');
            const studyDesc = study.description.toLowerCase();
            return studyTitle.includes(cleanProjectName) || 
                   studyDesc.includes(data.project.toLowerCase());
          });
          if (matchingProject) {
            navigate(`/project/${matchingProject.id}`);
          }
        }
      }
    } catch (error) {
      console.error('Navigation error:', error);
    }
  };

  const sendMessageToChatGPT = async (userMessage, hidePrompt = false, mode, callback) => {
    // Debug logs for mode
    console.log('sendMessageToChatGPT called with mode:', mode);

    // Check if this is a work-related query
    const isWorkQuery = /work|portfolio|projects|experience/i.test(userMessage.toLowerCase());
    
    // Check if this is a home navigation request
    const isHomeRequest = /(?:take me |go |let's |start )?(?:home|over|back|fresh|beginning|start over)/i.test(userMessage.toLowerCase());
    
    let updatedMessages;
    if (hidePrompt) {
      updatedMessages = [
        {
          role: "user",
          content: userMessage,
          hidden: true,
          avatar: selectedAvatar,
        },
      ];
    } else {
      updatedMessages = [
        ...messages,
        { 
          role: "user", 
          content: userMessage, 
          avatar: selectedAvatar,
          isWorkQuery: isWorkQuery && isFirstWorkQuery,
          isHomeRequest: isHomeRequest
        },
      ];
    }

    // If this was a work query, mark it as not first anymore
    if (isWorkQuery && isFirstWorkQuery) {
      setIsFirstWorkQuery(false);
    }

    // If this is a home request, we'll reset the first work query state after navigation
    if (isHomeRequest) {
      setIsFirstWorkQuery(true);
    }

    setMessages(updatedMessages);
    localStorage.setItem("chatMessages", JSON.stringify(updatedMessages));

    const textMessages = updatedMessages
      .filter((msg) => !msg.hidden)
      .slice(-MAX_MESSAGES)
      .map((msg) => {
        if (msg.knowledge) {
          return {
            role: msg.role,
            content: msg.knowledge,
          };
        } else {
          return msg;
        }
      });

    // Select the prompt based on the mode
    const selectedPrompt = mode === 'work' 
      ? CHATBOT_CONTEXT.workPrompt 
      : mode === 'advice'
      ? CHATBOT_CONTEXT.advicePrompt
      : CHATBOT_CONTEXT.mentoringPrompt;

    console.log('Selected mode:', mode);
    console.log('Selected prompt type:', 
      mode === 'work' ? 'workPrompt' 
      : mode === 'advice' ? 'advicePrompt' 
      : 'mentoringPrompt'
    );

    // Add portfolio knowledge to the system message
    const systemMessage = {
      role: "system",
      content: `${selectedPrompt}\n\nPortfolio Knowledge:\n${currentKnowledge}`
    };

    const requestBody = JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [
        systemMessage,
        ...textMessages,
      ],
      max_tokens: 4000,
    });

    // Log the messages being sent to ChatGPT
    const requestData = JSON.parse(requestBody);
    console.log('Sending to ChatGPT - Mode:', mode);
    console.log('System Prompt:', requestData.messages[0].content);
    console.log('Portfolio Knowledge included:', !!currentKnowledge);
    console.log('User Messages:', textMessages);

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: requestBody,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const botResponseContent = data.choices[0].message.content;
      console.log('Raw AI response:', botResponseContent);

      let type;
      let content;
      const typeMatch = botResponseContent.match(/#type:\s*"?(\w+)"?|#(\w+)/);
      if (typeMatch) {
        type = typeMatch[1] || typeMatch[2];
        content = botResponseContent
          .replace(/#type:\s*"?\w+"?|#\w+/, "")
          .trim();
      } else {
        type = "text";
        content = botResponseContent;
      }

      // Handle navigation type responses
      if (type === 'navigation') {
        try {
          // Extract JSON from the content using regex
          const jsonMatch = content.match(/\{[\s\S]*\}/);
          if (jsonMatch) {
            const navigationData = JSON.parse(jsonMatch[0]);
            handleNavigation(navigationData);
            // Use the text before the JSON as the message
            content = content.substring(0, jsonMatch.index).trim() || navigationData.message || "I'll take you to that project!";
          } else {
            throw new Error('No valid JSON found in navigation response');
          }
        } catch (error) {
          console.error('Error parsing navigation data:', error);
          content = "I'm sorry, I couldn't process that navigation request.";
        }
      }

      const botResponse = {
        role: "assistant",
        content,
        type,
      };

      const newMessages = [...updatedMessages, botResponse];
      setMessages(newMessages);
      localStorage.setItem("chatMessages", JSON.stringify(newMessages));

      // If this is a voice chat response, call the callback with the content
      if (mode === 'voice' && callback) {
        console.log('Calling voice callback with response:', content);
        callback(content);
      }

    } catch (error) {
      console.error("Error:", error);
      const errorMessage = `Error: ${error.message}`;
      setMessages((prev) => [
        ...prev,
        { content: errorMessage, role: "assistant" },
      ]);
      if (mode === 'voice' && callback) {
        callback(errorMessage);
      }
    }
  };

  const handleSendMessage = (text, contextResponse, callback) => {
    if (!text.trim()) return;
    
    // If we have a context response, use that instead of calling OpenAI
    if (contextResponse) {
      setMessages(prev => [
        ...prev, 
        { role: 'user', content: text, avatar: selectedAvatar },
        { role: 'assistant', content: contextResponse }
      ]);
      if (callback) {
        callback(contextResponse);
      }
      return;
    }
    
    // Regular OpenAI handling - pass the current chatMode and callback
    console.log('handleSendMessage with mode:', chatMode);
    sendMessageToChatGPT(text, false, chatMode || 'work', callback);
    setInputText("");
    setShowQuestions(false);
  };

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar);
    // Send initial greeting when avatar is selected
    const initialPrompt = "just say hello";
    sendMessageToChatGPT(initialPrompt, true, chatMode || 'work');
  };

  const handleQuestionSelect = (question) => {
    sendMessageToChatGPT(question, false, chatMode || 'work');
    setShowQuestions(false);
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<App />} />
        <Route 
          path="/project/:projectId" 
          element={
            <Project 
              onViewContextChange={setCurrentViewContext}
            />
          } 
        />
        <Route path="/whitepaper" element={<Whitepaper />} />
      </Routes>

      <ChatBot
        messages={messages}
        onSendMessage={handleSendMessage}
        inputText={inputText}
        setInputText={setInputText}
        selectedAvatar={selectedAvatar}
        onAvatarSelect={handleAvatarSelect}
        showQuestions={showQuestions}
        setShowQuestions={setShowQuestions}
        preSelectedQuestions={preSelectedQuestions}
        handleQuestionSelect={handleQuestionSelect}
        chatMode={chatMode}
        setChatMode={setChatMode}
        currentViewContext={currentViewContext}
        onNavigation={handleNavigation}
      />

      <PortfolioOverlay
        isOpen={isPortfolioOverlayOpen}
        onClose={() => setIsPortfolioOverlayOpen(false)}
        caseStudies={contentData.portfolio.caseStudies}
        onCaseStudySelect={(id) => {
          navigate(`/project/${id}`);
          setIsPortfolioOverlayOpen(false);
        }}
      />
    </>
  );
};

// Main component that provides Router context
const AppRoutes = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default AppRoutes; 