import React, { useState, useRef, useEffect } from 'react';
import './ChatBot.css';
import './ChatBubble.css';

const ChatBot = ({ 
  messages, 
  onSendMessage, 
  inputText, 
  setInputText, 
  selectedAvatar,
  onAvatarSelect,
  showQuestions,
  setShowQuestions,
  preSelectedQuestions,
  handleQuestionSelect,
  chatMode,
  setChatMode,
  currentViewContext,
  onNavigation,
  closePortfolioOverlay
}) => {
  // Core state
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [hasSeenIntro, setHasSeenIntro] = useState(false);
  const [conversationStartTime, setConversationStartTime] = useState(null);
  const [isLive, setIsLive] = useState(false);
  const [hasUsedLiveBefore, setHasUsedLiveBefore] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(() => {
    const interacted = localStorage.getItem('hasInteractedWithChat');
    return interacted === 'true';
  });

  // Voice chat state
  const [isVoiceChatActive, setIsVoiceChatActive] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [currentResponseText, setCurrentResponseText] = useState('');

  // WebRTC refs
  const peerConnectionRef = useRef(null);
  const dataChannelRef = useRef(null);
  const audioElementRef = useRef(null);
  const mediaStreamRef = useRef(null);

  // UI refs
  const chatEndRef = useRef(null);
  const inputGroupRef = useRef(null);
  const dropdownRef = useRef(null);

  // Audio context for development mode
  const audioContextRef = useRef(null);
  const oscillatorRef = useRef(null);

  // Initialize speech synthesis
  const synth = window.speechSynthesis;
  const [voices, setVoices] = useState([]);

  // Load voices when component mounts
  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = synth.getVoices();
      setVoices(availableVoices);
      console.log('🎤 Available voices:', availableVoices.map(v => v.name));
    };

    loadVoices();
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = loadVoices;
    }

    return () => {
      if (speechSynthesis.onvoiceschanged !== undefined) {
        speechSynthesis.onvoiceschanged = null;
      }
    };
  }, []);

  // Speak text using OpenAI TTS
  const speakWithTTS = async (text) => {
    try {
      console.log('🎤 Requesting TTS for:', text);
      
      const response = await fetch('/api/tts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text,
          voice: 'echo', // OpenAI voice options: alloy, echo, fable, onyx, nova, shimmer
          model: 'tts-1',
        }),
      });

      if (!response.ok) {
        throw new Error(`TTS request failed: ${response.status}`);
      }

      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);

      return new Promise((resolve, reject) => {
        audio.onended = () => {
          URL.revokeObjectURL(audioUrl);
          console.log('✅ TTS playback finished');
          resolve();
        };

        audio.onerror = (error) => {
          URL.revokeObjectURL(audioUrl);
          console.error('❌ TTS playback error:', error);
          reject(error);
        };

        console.log('▶️ Starting TTS playback');
        audio.play().catch(error => {
          console.error('❌ TTS playback failed:', error);
          reject(error);
        });
      });
    } catch (error) {
      console.error('❌ TTS request failed:', error);
      throw error;
    }
  };

  // Scroll to bottom when messages change
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Handle chat expansion
  useEffect(() => {
    if (isExpanded) {
      document.body.classList.add('chat-expanded');
      document.querySelector('.project-container')?.classList.add('chat-expanded');
    } else {
      document.body.classList.remove('chat-expanded');
      document.querySelector('.project-container')?.classList.remove('chat-expanded');
    }

    return () => {
      document.body.classList.remove('chat-expanded');
      document.querySelector('.project-container')?.classList.remove('chat-expanded');
    };
  }, [isExpanded]);

  // Load hasSeenIntro from localStorage on mount
  useEffect(() => {
    const seen = localStorage.getItem('hasSeenIntro');
    if (seen) {
      setHasSeenIntro(true);
    }
  }, []);

  // Load Live mode usage state
  useEffect(() => {
    const hasUsedLive = localStorage.getItem('hasUsedLive');
    if (hasUsedLive) {
      setHasUsedLiveBefore(true);
    }
  }, []);

  // Initialize audio context
  const initAudioContext = async () => {
    try {
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      }
      
      // Resume audio context - required for browsers
      if (audioContextRef.current.state === 'suspended') {
        await audioContextRef.current.resume();
      }
      console.log('🔊 Audio context initialized and resumed:', audioContextRef.current.state);
    } catch (error) {
      console.error('Failed to initialize audio context:', error);
    }
  };

  // Handle live mode toggle
  const handleLiveToggle = async () => {
    try {
      const newLiveState = !isLive;
      
      if (newLiveState) {
        setIsConnecting(true);
        await initializeVoiceChat();
        setIsLive(true);
        if (!hasUsedLiveBefore) {
          localStorage.setItem('hasUsedLive', 'true');
          setHasUsedLiveBefore(true);
        }
      } else {
        cleanupVoiceChat();
        setIsLive(false);
      }
    } catch (error) {
      console.error('Failed to toggle live mode:', error);
      setIsLive(false);
      setIsConnecting(false);
    }
  };

  // Play development audio
  const playDevelopmentAudio = async (duration = 2000, type = 'speaking', text = '') => {
    try {
      if (!audioContextRef.current) {
        console.error('❌ No audio context available');
        return;
      }

      // Ensure audio context is resumed
      if (audioContextRef.current.state === 'suspended') {
        await audioContextRef.current.resume();
      }

      console.log('🔊 Playing audio:', type, 'Context state:', audioContextRef.current.state);
      
      if (type === 'speaking' && text) {
        // Use OpenAI TTS for speech
        await speakWithTTS(text);
      } else if (type === 'connect') {
        // Play notification sound
        const oscillator = audioContextRef.current.createOscillator();
        const gainNode = audioContextRef.current.createGain();

        oscillator.type = 'sine';
        oscillator.frequency.setValueAtTime(880, audioContextRef.current.currentTime);
        oscillator.frequency.linearRampToValueAtTime(440, audioContextRef.current.currentTime + 0.2);
        gainNode.gain.setValueAtTime(0.5, audioContextRef.current.currentTime);

        oscillator.connect(gainNode);
        gainNode.connect(audioContextRef.current.destination);

        gainNode.gain.exponentialRampToValueAtTime(
          0.001,
          audioContextRef.current.currentTime + 0.3
        );

        oscillator.start();
        oscillator.stop(audioContextRef.current.currentTime + 0.3);

        return new Promise(resolve => setTimeout(resolve, 300));
      }
    } catch (error) {
      console.error('❌ Failed to play audio:', error);
    }
  };

  // Handle Realtime API events
  const handleRealtimeEvent = async (event) => {
    try {
      const data = JSON.parse(event.data);
      console.log('📥 Received event:', data);

      const checkForIntent = (text) => {
        // First check for intent_check responses
        const typeMatch = text.match(/#type:\s*"intent_check"/i);
        const modeMatch = text.match(/#mode:\s*"(portfolio|mentoring)"/i);
        const responseMatch = text.match(/#response:\s*"([^"]*)"/i);

        if (typeMatch && modeMatch && responseMatch) {
          const mode = modeMatch[1];
          const response = responseMatch[1];
          
          // Switch chat mode based on intent
          if (mode !== chatMode) {
            console.log('🔄 Switching mode from', chatMode, 'to', mode);
            setChatMode(mode);
          }
          
          // Return just the response text
          return response;
        }

        return text;
      };

      const checkForNavigation = (text) => {
        // First check for JSON navigation commands
        const jsonMatch = text.match(/\{[\s\S]*\}/);
        if (jsonMatch) {
          try {
            const navigationData = JSON.parse(jsonMatch[0]);
            console.log('🎯 Found navigation data:', navigationData);
            if (onNavigation) {
              onNavigation(navigationData);
            }
            return true;
          } catch (error) {
            console.error('Error parsing navigation JSON:', error);
          }
        }

        // Then check for natural language patterns
        const navigationPatterns = [
          { 
            pattern: /(?:let me |I'll |here's |showing you |take you to )(?:my )?work (?:at |on |with |for )?(?:the )?google/i, 
            project: '1000', 
            blockId: '1000' 
          },
          { 
            pattern: /(?:let me |I'll |here's |showing you |take you to )(?:my )?work (?:at |on |with |for )?(?:the )?gopro/i, 
            project: '6000', 
            blockId: '6000' 
          },
          { 
            pattern: /(?:let me |I'll |here's |showing you |take you to )(?:my )?work (?:at |on |with |for )?(?:the )?think diff/i, 
            project: '3000', 
            blockId: '3101' 
          },
          { 
            pattern: /(?:let me |I'll |here's |showing you )(?:all |)(?:my |the )?(?:work|projects)/i, 
            action: 'expand' 
          },
          { 
            pattern: /(?:take|go|let's go) (?:me )?(?:back )?(?:to the )?home/i, 
            action: 'home' 
          }
        ];

        for (const pattern of navigationPatterns) {
          if (pattern.pattern.test(text)) {
            const navigationData = pattern.action ? 
              { action: pattern.action, message: text } :
              { action: 'navigate', project: pattern.project, blockId: pattern.blockId, message: text };
            
            console.log('🎯 Navigation intent detected:', navigationData);
            if (onNavigation) {
              onNavigation(navigationData);
            }
            return true;
          }
        }
        return false;
      };

      switch (data.type) {
        case 'session.created':
          console.log('🎉 Session created, triggering initial greeting...');
          if (dataChannelRef.current?.readyState === 'open') {
            dataChannelRef.current.send(JSON.stringify({
              type: 'response.create'
            }));
          }
          break;
        case 'session.updated':
          console.log('🔄 Session updated:', data.session);
          break;
        case 'speech.started':
          console.log('🗣️ Agent started speaking');
          setIsSpeaking(true);
          break;
        case 'speech.ended':
          console.log('🤫 Agent finished speaking');
          setIsSpeaking(false);
          break;
        case 'speech.understanding':
          // Check user's speech for navigation intent
          if (data.understanding?.text) {
            console.log('🧠 Understanding user speech:', data.understanding.text);
            checkForNavigation(data.understanding.text);
          }
          break;
        case 'response.content_part.done':
          // Handle voice responses
          if (data.part?.transcript) {
            console.log('📝 Voice response transcript:', data.part.transcript);
            // Check for intent and get cleaned response
            const cleanedResponse = checkForIntent(data.part.transcript);
            // Add the response to the chat history
            onSendMessage(cleanedResponse, null, 'assistant');
            // Check for navigation intent in the transcript
            checkForNavigation(cleanedResponse);
          }
          break;
        case 'response.audio_transcript.delta':
          // Accumulate the transcript text
          if (data.delta?.text) {
            setCurrentResponseText(prev => prev + data.delta.text);
          }
          break;
        case 'response.audio_transcript.done':
          // Process the complete response
          console.log('📝 Complete response:', currentResponseText);
          
          // Check for intent and get cleaned response
          const cleanedResponse = checkForIntent(currentResponseText);
          
          // Add the response to the chat history
          onSendMessage(cleanedResponse, null, 'assistant');

          // Check for navigation intent in the response
          checkForNavigation(cleanedResponse);
          
          // Reset the response text for the next interaction
          setCurrentResponseText('');
          break;
        case 'error':
          console.error('❌ Realtime API error:', data.error);
          break;
        default:
          console.log('❓ Unhandled event type:', data.type);
      }
    } catch (error) {
      console.error('Error handling realtime event:', error);
    }
  };

  // Handle microphone input
  const handleMicrophoneData = (audioData) => {
    if (dataChannelRef.current?.readyState === 'open') {
      dataChannelRef.current.send(JSON.stringify({
        type: 'audio.data',
        data: audioData,
        timestamp: Date.now()
      }));
    }
  };

  // Cleanup WebRTC resources
  const cleanupVoiceChat = () => {
    try {
      if (dataChannelRef.current) {
        dataChannelRef.current.close();
        dataChannelRef.current = null;
      }

      if (peerConnectionRef.current) {
        peerConnectionRef.current.getSenders().forEach((sender) => {
          if (sender.track) {
            sender.track.stop();
          }
        });
        peerConnectionRef.current.close();
      }

      if (audioElementRef.current) {
        audioElementRef.current.srcObject = null;
      }

      peerConnectionRef.current = null;
      setIsVoiceChatActive(false);
      setIsListening(false);
      setIsSpeaking(false);
      console.log('🧹 Cleaned up voice chat resources');
            } catch (error) {
      console.error('❌ Error during cleanup:', error);
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      cleanupVoiceChat();
    };
  }, []);

  // Handle voice chat mode selection
  const handleVoiceModeSelect = async () => {
    setChatMode('voice');
    await initializeVoiceChat();
  };

  const handleSendMessage = (text) => {
    const contextQuestions = [
      'what am i looking at',
      'what is this',
      'where am i',
      'which section is this',
      'what page is this'
    ];

    if (contextQuestions.some(q => text.toLowerCase().includes(q))) {
      let contextResponse = '';
      if (currentViewContext?.projectTitle) {
        contextResponse = `You're currently viewing the "${currentViewContext.projectTitle}" case study. `;
        if (currentViewContext?.currentBlock?.title) {
          contextResponse += `Specifically, you're reading the "${currentViewContext.currentBlock.title}" section.`;
        }
      }
      
      if (contextResponse) {
        onSendMessage(text, contextResponse);
        return;
      }
    }

    onSendMessage(text);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(inputText);
    }
  };

  const toggleChat = () => {
    setIsExpanded(!isExpanded);
  };

  const formatTimestamp = (date) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    
    if (date >= today) {
      return `Today ${date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}`;
    } else if (date >= yesterday) {
      return `Yesterday ${date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}`;
    } else {
      return date.toLocaleDateString('en-US', { 
        month: 'short', 
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit'
      });
    }
  };

  const handleModeSelect = async (mode) => {
    console.log('Mode selected:', mode);
    setChatMode(mode);
    
      if (!hasSeenIntro) {
        setIsTyping(true);
        localStorage.setItem('hasSeenIntro', 'true');
        setHasSeenIntro(true);
      }

      if (!conversationStartTime) {
        setConversationStartTime(new Date());
      }
      
      const initialPrompt = mode === 'work' 
        ? "Hi, I'd like to learn more about your work"
        : "I'm looking for design career mentoring. I'd like guidance on my professional development as a designer.";
      
      onSendMessage(initialPrompt);
      
      if (!hasSeenIntro) {
        setTimeout(() => setIsTyping(false), 2000);
    }
  };

  const handleBackClick = () => {
    setChatMode(null);
  };

  const TypingIndicator = () => (
    <div className="typing-indicator">
      <div className="typing-indicator-bubble">
        <div className="typing-indicator-dot"></div>
        <div className="typing-indicator-dot"></div>
        <div className="typing-indicator-dot"></div>
      </div>
    </div>
  );

  // Initialize WebRTC connection
  const initializeVoiceChat = async () => {
    try {
      console.log('🎤 Initializing voice chat...');
      
      // Check for API key
      const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
      console.log('🔑 API Key available:', !!apiKey, 'Length:', apiKey?.length);
      console.log('🔑 First few chars:', apiKey?.substring(0, 7));
      
      if (!apiKey) {
        throw new Error('OpenAI API key not found. Please add REACT_APP_OPENAI_API_KEY to your .env file.');
      }
      
      // Create peer connection
      const pc = new RTCPeerConnection();
      peerConnectionRef.current = pc;

      // Set up audio playback
      const audioEl = document.createElement('audio');
      audioEl.autoplay = true;
      audioElementRef.current = audioEl;
      
      pc.ontrack = (e) => {
        audioEl.srcObject = e.streams[0];
        console.log('🎧 Received audio track');
      };

      // Add local audio track for microphone
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          channelCount: 1,
          sampleRate: 48000,
          sampleSize: 16
        }
      });
      mediaStreamRef.current = stream;
      pc.addTrack(stream.getTracks()[0]);

      // Create audio processor to handle microphone data
      const audioContext = new AudioContext();
      const source = audioContext.createMediaStreamSource(stream);
      const processor = audioContext.createScriptProcessor(1024, 1, 1);
      
      source.connect(processor);
      processor.connect(audioContext.destination);
      
      processor.onaudioprocess = (e) => {
        if (isListening && dataChannelRef.current?.readyState === 'open') {
          const inputData = e.inputBuffer.getChannelData(0);
          dataChannelRef.current.send(JSON.stringify({
            type: 'audio.data',
            data: Array.from(inputData),
            timestamp: Date.now()
          }));
        }
      };
      
      // Set up data channel for events
      const dc = pc.createDataChannel("oai-events");
      dataChannelRef.current = dc;
      
      dc.addEventListener("message", handleRealtimeEvent);
      dc.addEventListener("open", () => {
        console.log('📡 Data channel opened');
        
        // Initialize the session with configuration
        dc.send(JSON.stringify({
          type: 'session.update',
          session: {
            model: 'gpt-4o-realtime-preview',
            modalities: ['audio', 'text'],
            instructions: hasUsedLiveBefore 
              ? `You are David Lee's AI assistant. Since this user has used voice chat before, just say "Welcome back! How can I help you today?" and wait for their response. Keep all future responses natural and conversational.`
              : `You are David Lee's AI operator assistant. Your primary role is to understand what the user needs and direct them to the right service. There are two main services available:

1. Portfolio Presentation
- Showing David's work and projects
- Navigating through case studies
- Explaining project details and impact

2. Career Mentoring
- Design career guidance
- UX/UI skill development
- Professional growth advice

First Time Greeting:
"Hi! I'm David's AI assistant. I can help you explore David's portfolio or provide career mentoring. Which would you prefer?"

Key Responsibilities:
1. Determine user's intent (portfolio vs mentoring)
2. Direct conversation to appropriate mode
3. Monitor for intent changes
4. Handle mode switching smoothly

Intent Detection:
- Portfolio keywords: "work", "projects", "portfolio", "case studies", "experience"
- Mentoring keywords: "advice", "career", "guidance", "mentoring", "growth", "skills"

Mode Switching:
- If user switches topics, acknowledge the change
- Example: "I see you're interested in mentoring now. I'd be happy to switch gears and help with your career development."

Navigation Commands:
- For portfolio requests, use standard navigation commands
- For mentoring, focus on career guidance and professional development

Response Format:
Always structure responses as:
type: "intent_check"
mode: "portfolio" or "mentoring"
response: "Your actual response text"

Example:
User: "I want to see David's work"
type: "intent_check"
mode: "portfolio"
response: "I'll help you explore David's portfolio. Would you like to see an overview of all projects or focus on a specific area?"

User: "Actually, I need career advice"
type: "intent_check"
mode: "mentoring"
response: "I understand you'd like career guidance now. To better help you, could you tell me about your current role and what specific areas you'd like to develop?"

Keep responses natural and conversational while maintaining clear intent detection and mode management.`,
            voice: 'echo',
            input_audio_format: 'pcm16',
            output_audio_format: 'pcm16',
            input_audio_transcription: {
              model: 'whisper-1'
            },
            turn_detection: {
              type: 'server_vad',
              threshold: 0.5,
              prefix_padding_ms: 300,
              silence_duration_ms: 500,
              create_response: true
            },
            temperature: 0.8,
            max_response_output_tokens: 'inf'
          }
        }));
      });

      // Create and send offer
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);

      const baseUrl = "https://api.openai.com/v1/realtime";
      const model = "gpt-4o-realtime-preview";
      
      console.log('🔑 Using API key type:', apiKey?.startsWith('sk-proj-') ? 'Project key' : 'Regular key');
      
      console.log('📡 Sending WebRTC offer to OpenAI...');
      const sdpResponse = await fetch(`${baseUrl}?model=${model}`, {
        method: "POST",
        body: offer.sdp,
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/sdp",
          "OpenAI-Beta": "realtime=v1"
        },
      });

      if (!sdpResponse.ok) {
        console.error('❌ OpenAI response status:', sdpResponse.status);
        const errorText = await sdpResponse.text();
        console.error('❌ OpenAI error:', errorText);
        throw new Error(`Failed to establish connection: ${sdpResponse.status} - ${errorText}`);
      }

      const answer = {
        type: "answer",
        sdp: await sdpResponse.text(),
      };
      await pc.setRemoteDescription(answer);

      // Add connection state change handler
      pc.addEventListener('connectionstatechange', () => {
        console.log('🔌 Connection state:', pc.connectionState);
        if (pc.connectionState === 'connected') {
          setIsVoiceChatActive(true);
          setIsListening(true);
          
          // Trigger initial greeting
          if (dataChannelRef.current?.readyState === 'open') {
            console.log('👋 Triggering initial greeting...');
            dataChannelRef.current.send(JSON.stringify({
              type: 'audio.transcription',
              text: '',
              timestamp: Date.now()
            }));
          }
        } else if (pc.connectionState === 'failed' || pc.connectionState === 'disconnected') {
          console.error('❌ WebRTC connection failed or disconnected');
          cleanupVoiceChat();
          setIsLive(false);
        }
      });

    } catch (error) {
      console.error('❌ Voice chat initialization failed:', error);
      cleanupVoiceChat();
      throw error;
    } finally {
      setIsConnecting(false);
    }
  };

  const handleModeClick = (isVoiceClick) => {
    if (!hasInteracted) {
      setHasInteracted(true);
      localStorage.setItem('hasInteractedWithChat', 'true');
    }
    if (isVoiceClick) {
      !isLive && handleLiveToggle();
    } else {
      isLive && handleLiveToggle();
    }
  };

  const handleNavigation = (path) => {
    // Close the portfolio overlay if it's open
    closePortfolioOverlay?.();
    
    // For specific project navigation (not portfolio overview), add delay to allow overlay to close
    if (path.action === 'navigate' && path.project !== 'portfolio') {
      setTimeout(() => {
        onNavigation(path);
      }, 300); // Delay to match overlay closing animation
    } else {
      // For portfolio overview or home navigation, navigate immediately
      onNavigation(path);
    }
  };

  return (
    <div className={`chatbot-container ${isExpanded ? 'expanded' : ''}`}>
      {!isExpanded && (
        <div className="chat-controls-top">
          <div className="chat-controls-group">
            <button 
              className={`mode-option ${!isLive ? 'active' : ''}`}
              onClick={() => handleModeClick(false)}
            >
              <span className="mode-label">Text</span>
            </button>
            <button 
              className={`mode-option ${isLive ? 'active' : ''}`}
              onClick={() => handleModeClick(true)}
              disabled={isConnecting}
            >
              <span className="mode-label">{isConnecting ? 'Connecting...' : 'Voice'}</span>
            </button>
            <button 
              className={`mode-option go-option ${isLive ? 'hidden' : ''}`}
              onClick={toggleChat}
            >
              <span className="mode-label">Go</span>
            </button>
          </div>
          {!hasInteracted && (
            <div className="chat-tooltip">
              <span className="pointing-hand" role="img" aria-label="waving hand">👋</span>
              Try all new chat with David!
            </div>
          )}
        </div>
      )}
      
      {isExpanded && (
        <div className="chat-panel">
          <div className="chat-header">
            {chatMode && (
              <button className="back-button" onClick={handleBackClick}>
                <span className="back-arrow">‹</span>
                <span>Options</span>
              </button>
            )}
            <div className="chat-header-content">
              <div className="chat-header-profile">
                <img src="/images/david-producthunt.gif" alt="David's Profile" />
                <span className="active-status"></span>
              </div>
              <h3>David Lee</h3>
            </div>
            <button className="close-button" onClick={toggleChat}>
              <span className="collapse-icon">−</span>
            </button>
          </div>
          
          <div className="chat-content-container">
            {!chatMode ? (
              <div className="mode-selection">
                <div className="welcome-emoji">👋</div>
                <h2>Hi there! Let's talk!</h2>
                <p className="mode-subtitle">What would you like to chat about today?</p>
                
                <div className="mode-buttons">
                  <button
                    onClick={() => handleModeSelect('work')}
                    className="mode-button work"
                  >
                    <div className="mode-icon">
                      <span>💼</span>
                    </div>
                    <div className="mode-text">
                      <h3>Learn about my work</h3>
                    </div>
                  </button>
                  
                  <button
                    onClick={() => handleModeSelect('mentoring')}
                    className="mode-button mentoring"
                  >
                    <div className="mode-icon">
                      <span>🎨</span>
                    </div>
                    <div className="mode-text">
                      <h3>Design mentoring</h3>
                    </div>
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="chat-messages">
                  {conversationStartTime && (
                    <div className="timestamp-divider">
                      {formatTimestamp(conversationStartTime)}
                    </div>
                  )}
                  {messages.map((message, index) => (
                    message.content !== "just say hello" && (
                      <div
                        key={index}
                        className={`message ${message.role === 'user' ? 'user' : 'assistant'}`}
                      >
                        <div className="message-content">
                          {message.content}
                        </div>
                      </div>
                    )
                  ))}
                  {isTyping && (
                    <div className="typing-indicator">
                      <div className="typing-indicator-dot"></div>
                      <div className="typing-indicator-dot"></div>
                      <div className="typing-indicator-dot"></div>
                    </div>
                  )}
                  <div ref={chatEndRef} />
                </div>

                <div className="chat-input" ref={inputGroupRef}>
                  <textarea
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Type your message..."
                    rows="1"
                  />
                  <button 
                    onClick={() => handleSendMessage(inputText)} 
                    className="send-button"
                  >
                    Send
                  </button>
                </div>

                {showQuestions && (
                  <div className="questions-dropdown" ref={dropdownRef}>
                    {preSelectedQuestions.map((question, index) => (
                      <div
                        key={index}
                        className="question-option"
                        onClick={() => handleQuestionSelect(question)}
                      >
                        {question}
                      </div>
                    ))}
                  </div>
                )}

          {chatMode === 'voice' && (
            <div className="voice-controls">
              <div className="voice-status">
                      {isSpeaking ? (
                        <div className="voice-status-text">I'm speaking...</div>
                      ) : isListening ? (
                        <div className="voice-status-text">I'm listening...</div>
                      ) : (
                        <div className="voice-status-text">Voice chat ready</div>
                )}
              </div>
              <button 
                      className={`voice-button ${isListening ? 'listening' : ''} ${isSpeaking ? 'speaking' : ''}`}
                      onClick={() => setIsListening(!isListening)}
                      disabled={!isVoiceChatActive || isSpeaking}
              >
                <div className="mode-icon">
                  <img 
                    src="/images/voice-icon.svg" 
                    alt="Voice" 
                    onError={(e) => {
                      e.target.onerror = null; 
                      e.target.src = "https://api.iconify.design/mdi:microphone-outline.svg?color=white"
                    }} 
                  />
                </div>
              </button>
            </div>
                )}
              </>
          )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBot; 