// src/components/ContentBlock.js
import React from "react";
import Element from "./Element";

const ContentBlock = React.forwardRef(({ block, index, onVisibilityChange }, ref) => {
  if (!block) {
    console.error('ContentBlock received undefined block prop');
    return null;
  }

  // Default to 'default' type if not specified
  const blockType = block.type || 'default';

  // Check if the parent content block has type "grid"
  if (blockType === "grid") {
    return (
      <div 
        ref={ref}
        className={`element-grid ${blockType}`}
        data-index={index}
        data-block-id={block.id}
      >     {block.title && <h2>{block.title}</h2>}
        <div className="grid-container">
          {block.elements?.map((element, elementIndex) => (
            <div
              key={elementIndex}
              className="grid-item"
              style={{ backgroundColor: block.gridColor }} // Apply gridColor
            >
              <Element element={element} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  // Default rendering for other types
  return (
    <div 
      ref={ref}
      className={`content-block ${blockType}`}
      data-index={index}
      data-block-id={block.id}
    >
      {block.title && <h2>{block.title}</h2>}
      <div className="block-content">
        {block.elements?.map((element, elementIndex) => (
          <React.Fragment key={elementIndex}>
            <Element element={element} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
});

export default ContentBlock;
