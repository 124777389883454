import React from 'react';
import CaseStudyThumbnail from './CaseStudyThumbnail';
import './PortfolioOverlay.css';

const PortfolioOverlay = ({ isOpen, onClose, caseStudies, onCaseStudySelect }) => {
  if (!isOpen) return null;

  return (
    <div className="portfolio-overlay">
      <button className="close-overlay" onClick={onClose}>×</button>
      <div className="portfolio-overlay-content">
        <h2>Portfolio</h2>
        <div className="portfolio-grid">
          {caseStudies.map((caseStudy, index) => (
            <CaseStudyThumbnail
              key={caseStudy.id}
              caseStudy={caseStudy}
              delay={index * 0.2}
              onClick={() => {
                onCaseStudySelect(caseStudy.id);
                onClose();
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioOverlay; 