import React, { useState, useEffect, useRef } from "react";
import { TypeAnimation } from 'react-type-animation';
import "./Home.css";
import GradientOrbs from "./components/GradientOrbs";
import { createClient } from "pexels";
import Portfolio from "./components/Portfolio";
import contentData from "./UpdatedContent.json";
import { useNavigate } from 'react-router-dom';
import timelineData from './data/timeline.json';
import PortfolioNav from './components/PortfolioNav';

const App = () => {
  const [backgroundMedia, setBackgroundMedia] = useState([]);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [greeting, setGreeting] = useState("");
  const [city, setCity] = useState("");
  const [promptCount, setPromptCount] = useState(0);
  const [useVideoBackground, setUseVideoBackground] = useState(true);
  const [portfolioStyle, setPortfolioStyle] = useState({ display: "none" });
  const [visibleBlocks, setVisibleBlocks] = useState([]);
  const [initialResponse, setInitialResponse] = useState(true);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [expandedTimelineItem, setExpandedTimelineItem] = useState(null);
  const [showTypewriter, setShowTypewriter] = useState(true);
  const [showSecondCursor, setShowSecondCursor] = useState(false);
  const [showUpdatesText, setShowUpdatesText] = useState(false);
  const [showTimeline, setShowTimeline] = useState(false);
  const timelineRef = useRef(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isEntering, setIsEntering] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const [showPortfolioOverlay, setShowPortfolioOverlay] = useState(false);

  const client = createClient(process.env.REACT_APP_PEXELS_API_KEY);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch location data using ipinfo.io API
    fetch(`https://ipinfo.io/json?token=39efe9d46deca6`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Geolocation data:", data);
        setCity(data.city);
        fetchBackgroundMedia(`downtown ${data.city}`);

        const currentHour = new Date().getHours();
        let greetingMessage =
          currentHour < 12
            ? "Good morning"
            : currentHour < 18
            ? "Good afternoon"
            : "Good evening";
        setGreeting(greetingMessage);
      })
      .catch((error) => console.error("Error fetching location:", error));

    // Push initial state to the history stack
    window.history.pushState({ page: "app" }, "App", window.location.pathname);

    const handlePopState = (event) => {
      if (event.state && event.state.page === "app") {
        console.log("Navigating to the initial state");
      } else {
        window.history.pushState(
          { page: "app" },
          "App",
          window.location.pathname
        );
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMediaIndex(
        (prevIndex) => (prevIndex + 1) % backgroundMedia.length
      );
    }, 10000);
    return () => clearInterval(interval);
  }, [backgroundMedia]);

  const fetchBackgroundMedia = (query) => {
    client.videos
      .search({ query, per_page: 3 })
      .then((response) => {
        if (response.videos && response.videos.length > 0) {
          const videos = response.videos.map(
            (video) => video.video_files[0].link
          );
          setBackgroundMedia(videos);
        }
      })
      .catch((error) =>
        console.error("Error fetching background videos:", error)
      );
  };

  useEffect(() => {
    // Add enter animation when component mounts
    setIsEntering(true);
    setTimeout(() => {
      setIsEntering(false);
    }, 500); // Match the CSS transition duration
  }, []);

  useEffect(() => {
    // Check if user has visited before
    const hasVisited = localStorage.getItem('hasVisitedBefore');
    console.log('Initial state:', {
      hasVisited,
      showTypewriter: showTypewriter,
      showSecondCursor: showSecondCursor,
      showUpdatesText: showUpdatesText,
      showTimeline: showTimeline
    });
    
    // First visit - show typewriter animation
    console.log('Showing typewriter animation');

    setTimeout(() => {
      console.log('Setting showSecondCursor to true');
      setShowSecondCursor(true);
    }, 2000);

    setTimeout(() => {
      console.log('Setting showUpdatesText to true');
      setShowUpdatesText(true);
    }, 8000);

    setTimeout(() => {
      console.log('Setting showTimeline to true');
      setShowTimeline(true);
    }, 9000);

    // Add click handler to document
    const handleClick = () => {
      console.log('User clicked - switching to static version');
      setShowTypewriter(false);
      // Remove click handler after first click
      document.removeEventListener('click', handleClick);
    };

    document.addEventListener('click', handleClick);

    // Mark as visited for next time
    localStorage.setItem('hasVisitedBefore', 'true');
    console.log('Saved hasVisitedBefore to localStorage');

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);


  const handleNavigation = (path) => {
    setIsTransitioning(true);
    setIsNavExpanded(false); // Close nav after navigation
    setTimeout(() => {
      navigate(path);
    }, 1200); // Match the CSS transition duration
  };

  const handleThumbnailClick = (caseStudyId) => {
    const caseStudy = contentData.portfolio.caseStudies.find(study => study.id === caseStudyId);
    setIsNavExpanded(false); // Close nav after navigation
    if (caseStudy && caseStudy.liveLink && caseStudy.liveLink.startsWith('/')) {
      handleNavigation(caseStudy.liveLink);
    } else {
      handleNavigation(`/project/${caseStudyId}`);
    }
  };

  // Remove the old timeline events transformation
  const timelineEvents = timelineData.timelineEvents;

  const handleTimelineItemClick = (eventId) => {
    const newExpandedId = expandedTimelineItem === eventId ? null : eventId;
    setExpandedTimelineItem(newExpandedId);
    
    if (newExpandedId) {
      // Find the clicked timeline item
      const timelineItem = document.querySelector(`.timeline-item[data-id="${newExpandedId}"]`);
      if (timelineItem) {
        // Scroll the item into view with smooth behavior
        timelineItem.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  };

  const handleTimelineLinkClick = (e, link) => {
    e.stopPropagation();
    if (link && link.startsWith('/project/')) {
      const projectId = link.split('/').pop();
      handleNavigation(`/project/${projectId}`);
    } else if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <div className={`home-container ${isNavExpanded ? 'nav-expanded' : 'nav-collapsed'}`}>
      <PortfolioNav
        caseStudies={contentData.portfolio.caseStudies}
        onCaseStudySelect={(id) => navigate(`/project/${id}`)}
        currentCaseStudy={null}
        onExpand={setIsNavExpanded}
        isExpanded={isNavExpanded}
        textColor="#fff"
      />
      <GradientOrbs />

      {showTypewriter ? (
        <div className="intro-content-container">
          <div className="profile-container">
            <img
              src="/images/david-producthunt.gif"
              alt="Profile"
              className="profile-pic"
            />
          </div>
          <div className="greetingMiniline" style={{ width: '100%', padding: '0 16px', boxSizing: 'border-box', textAlign: 'center' }}>
            <TypeAnimation
              sequence={[
                'H', 1, 'Hi', 1, 'Hi,', 1, 'Hi, m', 1, 'Hi, my', 1,
                'Hi, my n', 1, 'Hi, my na', 1, 'Hi, my nam', 1,
                'Hi, my name', 1, 'Hi, my name i', 1, 'Hi, my name is', 1,
                'Hi, my name is D', 1, 'Hi, my name is Da', 1,
                'Hi, my name is Dav', 1, 'Hi, my name is Davi', 1,
                'Hi, my name is David.', 1,
              ]}
              speed={1}
              cursor={false}
              repeat={0}
              style={{ display: 'inline-block', textAlign: 'center', width: '100%' }}
            />
          </div>
          <h1 className="greetingHeadline" style={{ width: '100%', padding: '0 16px', boxSizing: 'border-box', textAlign: 'center' }}>
            <div style={{ 
              visibility: showTypewriter ? 'visible' : 'hidden',
              width: '100%',
              textAlign: 'center'
            }}>
              <TypeAnimation
                sequence={[
                  2500,
                  'I design AI products.',
                  1500,
                  'I design and build AI products.',
                ]}
                speed={50}
                className="greetingHeadline"
                cursor={showSecondCursor}
                style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
              />
            </div>
          </h1>
          <br></br>
          <div style={{ 
            opacity: showUpdatesText ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
            width: '100%',
            padding: '0 16px',
            boxSizing: 'border-box',
            textAlign: 'center'
          }}>
            <p className="greetingMiniline" style={{ marginBottom: '40px', width: '100%', textAlign: 'center' }}>
              Here is my latest work ↓
            </p>
          </div>

          <div 
            ref={timelineRef}
            className="timeline"
            style={{ 
              opacity: showTimeline ? 1 : 0,
              transform: `translateY(${showTimeline ? '0' : '20px'})`,
              transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
              visibility: showTimeline ? 'visible' : 'hidden'
            }}
          >
            {timelineEvents.map((event, index) => (
              <div 
                key={event.id} 
                data-id={event.id}
                className={`timeline-item ${expandedTimelineItem === event.id ? 'expanded' : ''}`}
              >         
                {event.logo && (
                  <div className="timeline-logo">
                    <img src={event.logo} alt={event.title} />
                  </div>
                )}
                <div 
                  className="timeline-content"
                  onClick={() => handleTimelineItemClick(event.id)}
                >         
                  <div className="timeline-year">{event.year}</div>
                  <h3>{event.title}</h3>
                  <p>{event.description}</p>
                  
                  <div className="timeline-detail">
                    {event.youtubeVideo ? (
                      <div className="timeline-detail-video">
                        <iframe
                          src={event.youtubeVideo}
                          title={event.title}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className="timeline-detail-image"
                        />
                      </div>
                    ) : event.detailImage && (
                      <img 
                        src={event.detailImage} 
                        alt={`${event.title} detail`} 
                        className="timeline-detail-image"
                      />
                    )}
                    <div className="timeline-detail-description">
                      {event.detailDescription || "During this time, I focused on developing innovative solutions and pushing the boundaries of what's possible in design and technology. Each project brought new challenges and opportunities for growth."}
                    </div>
                    <button 
                      className="timeline-view-button"
                      onClick={(e) => handleTimelineLinkClick(e, event.link)}
                    >
                      Visit
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <div className="timeline-line"></div>
          </div>
        </div>
      ) : (
        <div className="intro-content-container">
          <div className="profile-container-static">
            <img
              src="/images/david-producthunt.gif"
              alt="Profile"
              className="profile-pic"
            />
          </div>
          <div className="greetingMiniline-static" style={{ width: '100%', padding: '0 16px', boxSizing: 'border-box' }}>
            Hi, my name is David.
          </div>
          <h1 className="greetingHeadline-static" style={{ width: '100%', padding: '0 16px', boxSizing: 'border-box' }}>
            I design and build AI products.
          </h1>
          <br></br>
          <div style={{ width: '100%', padding: '0 16px', boxSizing: 'border-box' }}>
            <p className="greetingMiniline-static" style={{ marginBottom: '40px', width: '100%' }}>
              Here is my latest work ↓
            </p>
          </div>
          <div 
            ref={timelineRef}
            className="timeline-static"
          >
            {timelineEvents.map((event, index) => (
              <div 
                key={event.id} 
                data-id={event.id}
                className={`timeline-item ${expandedTimelineItem === event.id ? 'expanded' : ''}`}
              >         
                {event.logo && (
                  <div className="timeline-logo">
                    <img src={event.logo} alt={event.title} />
                  </div>
                )}
                <div 
                  className="timeline-content"
                  onClick={() => handleTimelineItemClick(event.id)}
                >         
                  <div className="timeline-year">{event.year}</div>
                  <h3>{event.title}</h3>
                  <p>{event.description}</p>
                  
                  <div className="timeline-detail">
                    {event.youtubeVideo ? (
                      <div className="timeline-detail-video">
                        <iframe
                          src={event.youtubeVideo}
                          title={event.title}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className="timeline-detail-image"
                        />
                      </div>
                    ) : event.detailImage && (
                      <img 
                        src={event.detailImage} 
                        alt={`${event.title} detail`} 
                        className="timeline-detail-image"
                      />
                    )}
                    <div className="timeline-detail-description">
                      {event.detailDescription || "During this time, I focused on developing innovative solutions and pushing the boundaries of what's possible in design and technology. Each project brought new challenges and opportunities for growth."}
                    </div>
                    <button 
                      className="timeline-view-button"
                      onClick={(e) => handleTimelineLinkClick(e, event.link)}
                    >
                      Visit
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <div className="timeline-line"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
