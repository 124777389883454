import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PortfolioNav.css';

const PortfolioNav = ({ caseStudies, onCaseStudySelect, currentCaseStudy, onExpand, textColor }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('PortfolioNav received caseStudies:', caseStudies);
  }, [caseStudies]);

  useEffect(() => {
    if (onExpand) {
      onExpand(isExpanded);
    }
  }, [isExpanded, onExpand]);

  // Handle menu toggle
  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
    if (onExpand) {
      onExpand(!isExpanded);
    }
  };

  // Handle navigation with auto-close
  const handleNavigation = (action) => {
    if (action === 'home') {
      navigate('/');
    } else {
      onCaseStudySelect(action);
    }
    // Auto-close after navigation with a slight delay
    setTimeout(() => {
      setIsExpanded(false);
      if (onExpand) {
        onExpand(false);
      }
    }, 300);
  };

  return (
    <nav 
      className={`portfolio-nav ${isExpanded ? 'expanded' : 'collapsed'} ${textColor === '#000' ? 'light-mode' : ''}`}
    >
      <div className="nav-items">
        <div
          className={`nav-item ${!currentCaseStudy ? 'active' : ''}`}
          onClick={() => isExpanded ? handleNavigation('home') : toggleMenu()}
        >
          {isExpanded ? (
            <svg className="nav-item-logo" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
              <polyline points="9 22 9 12 15 12 15 22"></polyline>
            </svg>
          ) : (
            <svg className="nav-item-logo" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          )}
          <div className="nav-item-content">
            <h4>Home</h4>
            <div className="social-icons">
              <a href="mailto:me@davidlee.design" title="Email">
                <svg className="social-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </svg>
              </a>
              <a href="/images/Resume.pdf" target="_blank" rel="noopener noreferrer" title="Resume">
                <svg className="social-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
              </a>
              <a href="https://www.linkedin.com/in/leedavid/" target="_blank" rel="noopener noreferrer" title="LinkedIn">
                <svg className="social-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                  <rect x="2" y="9" width="4" height="12"></rect>
                  <circle cx="4" cy="4" r="2"></circle>
                </svg>
              </a>
            </div>
          </div>
        </div>
        {caseStudies.map((study) => (
          <div
            key={study.id}
            className={`nav-item ${currentCaseStudy === study.id ? 'active' : ''}`}
            onClick={() => handleNavigation(study.id)}
          >
            <img 
              src={study.logo} 
              alt={study.title} 
              className="nav-item-logo"
            />
            <div className="nav-item-content">
              <h4>{study.title}</h4>
            </div>
          </div>
        ))}
      </div>
    </nav>
  );
};

export default PortfolioNav; 