import React, { useRef, forwardRef, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ContentBlock from "./ContentBlock";
import "./CaseStudy.css";

const CaseStudy = forwardRef(({ caseStudy, visibleBlocks, setVisibleBlocks, onBlockVisibilityChange }, ref) => {
  const contentBlockRefs = useRef([]);
  const navigate = useNavigate();
  const caseStudyRef = useRef(null);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.dataset.index);
          if (entry.isIntersecting && !visibleBlocks.includes(index)) {
            setVisibleBlocks(prev => [...prev, index]);
            if (onBlockVisibilityChange) {
              onBlockVisibilityChange(index, true);
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    contentBlockRefs.current.forEach(el => {
      if (el) observer.observe(el);
    });

    return () => {
      contentBlockRefs.current.forEach(el => {
        if (el) observer.unobserve(el);
      });
    };
  }, [visibleBlocks, setVisibleBlocks, onBlockVisibilityChange]);

  useEffect(() => {
    const handleScroll = () => {
      contentBlockRefs.current.forEach((el, index) => {
        if (!el) return;
        
        const rect = el.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        
        if (isVisible && !visibleBlocks.includes(index)) {
          setVisibleBlocks(prev => [...prev, index]);
          if (onBlockVisibilityChange) {
            onBlockVisibilityChange(index, true);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check initial visibility

    return () => window.removeEventListener('scroll', handleScroll);
  }, [visibleBlocks, setVisibleBlocks, onBlockVisibilityChange]);

  useEffect(() => {
    // Reset scroll position when project changes
    if (caseStudyRef.current) {
      caseStudyRef.current.scrollTo(0, 0);
    }
  }, [caseStudy.id]); // Reset scroll when project id changes

  const handleProfileClick = () => {
    // Only fade out when going home
    setIsFadingOut(true);
    setTimeout(() => {
      navigate('/');
    }, 300);
  };

  if (!caseStudy) {
    console.error('CaseStudy received undefined caseStudy prop');
    return null;
  }

  if (!caseStudy.contentBlocks) {
    console.error('CaseStudy received caseStudy without contentBlocks:', caseStudy);
    return null;
  }

  return (
    <div
      ref={ref}
      className={`case-study ${isFadingOut ? 'fade-out' : ''} ${caseStudy.backgroundClass || ''}`}
      style={{
        backgroundColor: caseStudy.backgroundColor || "#EFEBE0",
        color: caseStudy.textColor || "#000",
        overflowY: 'auto',
        height: '100vh'
      }}
    >
      <div className="logo-container">
        <img 
          src={caseStudy.logo} 
          alt="Profile" 
          className="logo-pic" 
          onClick={handleProfileClick}
          style={{ cursor: 'pointer' }}
        />
        <h1 style={{ fontFamily: caseStudy.textFont || "-apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Segoe UI', system-ui, Roboto, sans-serif" }}>
          {caseStudy.title}
        </h1>
        <h2>{caseStudy.description}</h2>
        <div className="CTA_Group">
          <a
            className="continue-button"
            href={caseStudy.liveLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundColor: caseStudy.buttonColor || "#ECECEC",
              color: caseStudy.textColor || "#000",
            }}
          >
            View live
          </a>
        </div>
      </div>
      <div className="content-blocks">
        {caseStudy.contentBlocks.map((contentBlock, index) => {
          if (!contentBlock) {
            console.error(`CaseStudy received undefined contentBlock at index ${index}`);
            return null;
          }
          
          return (
            <div
              key={contentBlock.id || index}
              ref={el => {
                if (el) {
                  contentBlockRefs.current[index] = el;
                }
              }}
              data-index={index}
              className={`content-block ${visibleBlocks.includes(index) ? 'visible' : ''}`}
              style={{
                willChange: 'transform, opacity',
                transform: 'none',
                opacity: 1,
                transition: 'none'
              }}
            >
              <ContentBlock
                ref={el => {
                  if (el) {
                    contentBlockRefs.current[index] = el;
                  }
                }}
                block={contentBlock}
                index={index}
                onVisibilityChange={onBlockVisibilityChange}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default CaseStudy;
