import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import contentData from '../UpdatedContent.json';
import './Project.css';
import PasswordProtection from './PasswordProtection';
import CaseStudy from './CaseStudy';
import PortfolioNav from './PortfolioNav';

const Project = ({ onViewContextChange }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [shouldRemount, setShouldRemount] = useState(0);
  const [currentViewContext, setCurrentViewContext] = useState({
    projectId: null,
    projectTitle: null,
    currentBlock: null
  });
  
  const project = contentData.portfolio.caseStudies.find(
    study => study.id === projectId
  );

  const [visibleBlocks, setVisibleBlocks] = useState([]);
  const caseStudyRef = useRef(null);

  const forceScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  };

  const handleNavigation = (newProjectId) => {
    if (newProjectId === projectId) return;
    
    setIsTransitioning(true);
    setTimeout(() => {
      forceScrollTop();
      setShouldRemount(prev => prev + 1);
      navigate(`/project/${newProjectId}`);
    }, 300);
  };

  const handleHomeClick = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      navigate('/');
    }, 500);
  };

  useEffect(() => {
    if (!project && contentData.portfolio.caseStudies.length > 0) {
      const firstProject = contentData.portfolio.caseStudies[0];
      navigate(`/project/${firstProject.id}`);
    }
  }, [project, navigate]);

  useEffect(() => {
    setVisibleBlocks([]);
    setIsUnlocked(false);
  }, [projectId]);

  useEffect(() => {
    if (project) {
      const updatedContext = {
        ...currentViewContext,
        projectId: project.id,
        projectTitle: project.title
      };
      setCurrentViewContext(updatedContext);
      if (onViewContextChange) {
        onViewContextChange(updatedContext);
      }
    }
  }, [project, onViewContextChange]);

  useEffect(() => {
    if (caseStudyRef.current) {
      caseStudyRef.current.scrollTo(0, 0);
    }
  }, [projectId]);

  useEffect(() => {
    if (project) {
      setTimeout(() => {
        setIsTransitioning(false);
      }, 50);
    }
  }, [project]);

  useEffect(() => {
    forceScrollTop();
  }, [projectId]);

  const handleBlockVisibilityChange = (blockIndex, isVisible) => {
    if (isVisible && project?.contentBlocks[blockIndex]) {
      const updatedContext = {
        ...currentViewContext,
        currentBlock: project.contentBlocks[blockIndex]
      };
      setCurrentViewContext(updatedContext);
      if (onViewContextChange) {
        onViewContextChange(updatedContext);
      }
    }
  };

  const handleUnlock = () => {
    setIsUnlocked(true);
  };

  if (!project) {
    return <div>Redirecting...</div>;
  }

  return (
    <div 
      className={`project-container ${!isNavExpanded ? 'nav-collapsed' : ''}`}
      style={{
        backgroundColor: project.backgroundColor || '#000'
      }}
    >
      <div 
        className={`project-content ${isTransitioning ? 'transitioning' : ''}`}
        style={{
          backgroundColor: project.backgroundColor || '#000'
        }}
      >
        {project.isPasswordProtected && !isUnlocked ? (
          <PasswordProtection 
            password={project.password} 
            onUnlock={handleUnlock}
          />
        ) : (
          <CaseStudy
            key={shouldRemount}
            ref={caseStudyRef}
            caseStudy={project}
            visibleBlocks={visibleBlocks}
            setVisibleBlocks={setVisibleBlocks}
            onBlockVisibilityChange={handleBlockVisibilityChange}
            currentViewContext={currentViewContext}
          />
        )}
      </div>
      <PortfolioNav
        caseStudies={contentData.portfolio.caseStudies}
        onCaseStudySelect={handleNavigation}
        currentCaseStudy={project.id}
        onExpand={setIsNavExpanded}
        isExpanded={isNavExpanded}
        textColor={project.textColor || '#000'}
      />
    </div>
  );
};

export default Project; 