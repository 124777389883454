import React, { useState } from 'react';
import './PasswordProtection.css';

const PasswordProtection = ({ password, onUnlock }) => {
  const [inputPassword, setInputPassword] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputPassword === password) {
      setError(false);
      onUnlock();
    } else {
      setError(true);
      setInputPassword('');
    }
  };

  return (
    <div className="password-protection">
      <div className="password-container">
        <h2>This project is password protected</h2>
        <p>Please enter the password to view this case study.</p>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="password"
              value={inputPassword}
              onChange={(e) => setInputPassword(e.target.value)}
              placeholder="Enter password"
              className={error ? 'error' : ''}
            />
            {error && <div className="error-message">Incorrect password</div>}
          </div>
          <button type="submit">Unlock</button>
        </form>
      </div>
    </div>
  );
};

export default PasswordProtection; 